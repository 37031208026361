<template>
    <div id="footer" class="gradient-template-footer back d-flex-r-w-ast-jsb-g1">
        <div class="logo-info-contact d-flex-r-w-ast-jfs basis-300 gradient-template-footer-contact back l-radius">
            <div class="logo-info d-flex-r-ac-gap15 gradient-template-footer-info back l-radius basis-400 grow-4">
                <img width="140" src="../assets/logo.png" alt="zarcoin">
                <div class="info d-flex-c-afs-jsb basis-100 grow-1">
                    <h3>صرافی زرکوین</h3>
                    <p>
                        زرکوین تابع قوانین جمهوری اسلامی ایران بوده و بستری برای تبادل دارایی های دیجیتال می باشد. هیج
                        گونه تبادل تبادل ارزی اعم از خرید و فروش دلار یا سایر ارزهای کاغذی، در این بستر صورت نمی
                        گیرد
                    </p>
                    <div class="social d-flex-r-jsa-ac-gap10">
                        <a>
                            <img :src="require('@/assets/Icons/Referral/share-linkedin.svg')" alt=""/>
                        </a>
                        <a>
                            <img :src="require('@/assets/Icons/Referral/share-twitter.svg')" alt=""/>
                        </a>
                        <a>
                            <img :src="require('@/assets/Icons/Referral/share-telegram.svg')" alt=""/>
                        </a>
                        <a>
                            <img :src="require('@/assets/Icons/Referral/share-instagram.svg')" alt=""/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="contacts basis-200 grow-1">
                <h3>تماس با ما</h3>
                <p>
                    آدرس خیابان مطهری، نرسیده به میرزای شیرازی، پلاک 299 واحد 3
                </p>
                <p>
                    ایمیل blahblah@blah.com
                </p>
                <p>
                    تلفن 021-88888888
                </p>
            </div>
        </div>
        <div class="help d-flex-r-w-jsa-gap20 basis-300">
            <div class="list">
                <h3>
                    دسترسی سریع
                </h3>
                <ul>
                    <li v-for="item in items1" :key="item.name">
                        <a @click="$push({name : item.route})">
                            {{item.name}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="list">
                <h3>
                    راهنما
                </h3>
                <ul>
                    <li v-for="item in items2" :key="item.name">
                        <a @click="$push({name : item.route})">
                            {{item.name}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {
                items1: [
                    {
                        name: 'ورود/ثبت نام',
                        route: 'Register',
                    },
                    {
                        name: 'کارمزد ها',
                        route: 'Fees',
                    },
                    {
                        name: 'بلاگ',
                        route: 'Blog',
                    },
                ],
                items2: [
                    {
                        name: 'قوانین و مقررات',
                        route: 'Policy',
                    },
                    {
                        name: 'راهنمای استفاده',
                        route: 'HowToUse',
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
    #footer {
        background: linear-gradient(180deg, #212529 18.42%, #404448 100%);

        .logo-info-contact {
            .logo-info {
                padding: 25px;

                .info {
                    height: 100%;

                    p {
                        font-size: var(--s-font);
                    }

                    .social {
                        background-color: #f0f0f0a3;
                        border-radius: 40px;
                        padding: 5px 10px;
                        width: 100%;

                        a {
                            img {
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }

            .contacts {
                text-align: right;
                padding: 25px;

                p {
                    font-size: var(--s-font);
                }
            }
        }

        .help {
            padding: 25px;

            .list {
                text-align: right;

                ul {
                    list-style: none;
                    line-height: 40px;
                }
            }
        }
    }
</style>