<template>
    <transition name="side-right">
        <div v-if="show" class="back-drop" @click.self="$emit('toggle',show=false)">
            <div class="main-menu card">
                <account-active v-if="state.token"/>
                <account-deactive v-else/>
                <a :class="{activeTab : $route.name === item.route.name}" class="item gradient-template-gold-linear"
                   v-for="item in items" :key="item.name" :href="$href(item.route)" @click.prevent="$push(item.route)">{{item.label}}</a>
            </div>
        </div>
    </transition>
</template>

<script>
    import AccountActive from "@/components/Navbar/Account/AccountActive";
    import AccountDeactive from "@/components/Navbar/Account/AccountDeactive";

    export default {
        name: "TouchMenu",
        components: {AccountDeactive, AccountActive},
        props: ['items', 'show'],
        emits:['toggle'],
        model: {
            prop: 'show',
            event: 'toggle'
        },
        data(){
            return{
                firstTransaction:0,
                transition : 0,
            }
        },
        methods:{}
    }
</script>

<style lang="scss" scoped>
    .back-drop {
        position: fixed;
        inset: 0;
        top: var(--navbar-h);
        backdrop-filter: blur(5px);
        background-color: var(--dark-backdrop);
        z-index: 101;
        padding-top: 5px;

        .main-menu {
            height: calc(100% - 10px);
            width: 70%;
            max-width: 300px;
            position: absolute;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            padding: 10px;
            color: var(--fontcolor);

            .item {
                padding: 10px 20px;
                text-align: right;
                border-radius: 5px;

                &:nth-child(even) {
                    background-color: var(--white-hover);
                }
            }
        }
    }
</style>