<template>
    <div id="app" ref="app">
        <navbar :items="menuItems" v-model="navShow" @dash="dash=!dash"/>
        <touch-menu :items="menuItems" v-model="navShow"/>
        <side-bar v-model="dash"/>
        <router-view/>
        <inline-svg class="gradient-template-gold-linear" :src="require('@/assets/yellow-gradient.svg')"/>
        <Footer v-if="$route.name === 'Home'"/>
    </div>
</template>

<script>

    import Navbar from "@/components/Navbar/Navbar";
    import TouchMenu from "@/components/Navbar/TouchMenu";
    import Footer from "@/components/Footer";
    import SideBar from "@/components/Panel/sidebar/SideBar";

    export default {
        name: 'App',
        components: {
            SideBar,
            Footer,
            TouchMenu,
            Navbar
        },
        data(){
            return{
                navShow:false,
                dash:false,
                menuItems: [
                    {
                        label: 'بازار ها',
                        name: 'Markets',
                        route: {name: 'Markets'}
                    },
                    /*{
                        label: 'کارمزد',
                        name: 'Fee',
                        route: {name: 'Wage'}
                    },*/
                    /*{
                        label: 'سوالات متداول',
                        name: 'FAQ',
                        route: {name: 'FAQ'}
                    },*/
                    /*{
                        label: 'درباره ما',
                        name: 'AboutUs',
                        route: {name: 'AboutUs'}
                    },*/
                    {
                        label: 'قوانین و مقررات',
                        name: 'Policy',
                        route: {name: 'Policy'}
                    },
                    {
                        label: 'بلاگ',
                        name: 'Blog',
                        route: {name: 'Blog'}
                    },
                    {
                        label: 'راهنما',
                        name: 'HowToUse',
                        route: {name: 'HowToUse'}
                    },
                ]
            }
        }
    }
</script>

<style>
    #app {
        font-family: "IRANSans", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        background-color: var(--dark);
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        --navbar-h : 60px;
    }
</style>
