<template>
    <a :href="$href(item.route)" @click.prevent="route"
       :class="{
        show : show || is(item),
        back: item.subMenu ? false : is(item),
    }"
       class="gradient-template-linear-sidebar sidebar-item d-flex-ac-jfs-gapc15-w xs-radius shrink-0">
        <inline-svg v-if="item.icon" width="18" :src="require('@/assets/Icons/menu/' + item.icon)"/>
        <span class="label">{{item.label}}</span>
        <div v-if="item.subMenu" class="subMenu grow-1 d-flex-c-ast-jfs-gap5"
             :style="{'--max' : item.subMenu.length*50 + ((item.subMenu.length-1) * 5) +'px'}">
            <side-bar-item v-for="subItem in item.subMenu" :key="subItem.name" :item="subItem"/>
        </div>
    </a>
</template>

<script>
    let _ = require('lodash');

    export default {
        name: "SideBarItem",
        props: ['item'],
        data() {
            return {
                show: false
            }
        },
        methods: {
            route() {
                if (this.item.subMenu) {
                    this.show = !this.show
                } else {
                    this.$push(this.item.route)
                }
            },
            is(item) {
                if (!item.subRoute) {
                    return this.$route.name === item.route.name
                } else {
                    let b, c, res = false
                    if (item.subMenu) {
                        for (const subItem of item.subMenu) {
                            b = _.get(subItem.route, subItem.subRoute)
                            c = _.get(this.$route, subItem.subRoute)
                            res = res ? res : b === c;
                        }
                    } else {
                        let keys = item.subRoute
                        b = _.get(item.route, keys)
                        c = _.get(this.$route, keys)
                        res = b === c;
                    }
                    return res
                }
            }
        }
    }
</script>

<style lang="scss">
    .sidebar-item {
        padding: 10px;
        background-color: var(--side-item);
        overflow: hidden;

        .subMenu {
            transition: max-height 0.3s, padding-top 0.3s;
            max-height: 0;
            overflow: hidden;
            padding-top: 0;
            flex-basis: 100%;
            margin: 0 -10px -10px;

            .sidebar-item {
                /*padding-right: 33px;*/
            }
        }

        &.show {
            .subMenu {
                max-height: var(--max);
                padding-top: 10px;
            }
        }

        &:hover {
            color: var(--side-color);
        }
    }
</style>