<template>
    <the-drop-down id="notifications" align="right">
        <template #label>
            <inline-svg class="active" title="اعلانات" :src="require('@/assets/Icons/bell.svg')" @click="$emit('Notifications')"/>
        </template>
        <template #icon>
            <span style="display: none"/>
        </template>
        <template #dropdown>
            <div class="notifications card d-flex-c-ast-jsb-gap20 p-10">
                <div v-for="n in 4" :key="n" class="notification">
                    <div class="header d-flex-ac-jsb">
                        <h4 class="m-font">برداشت از حساب</h4>
                        <small class="xs-font">12:34 - 00/2/14</small>
                    </div>
                    <p class="s-font">
                        برداشت از کیف پول بیت کوین به مقدار 5000 تومان انجام شد.
                    </p>
                </div>
                <div class="d-flex-ac-jfe s-font">
                    <a class="d-flex-ac-jfs-gap5 gradient-template-gold-linear text-gradient fill"
                       :href="$href({name :'Messages'})"
                       @click.prevent="$push({name :'Messages'})"
                    >
                        مشاهده پیام ها
                        <inline-svg height="10" :src="require('@/assets/Icons/arrow-left.svg')"/>
                    </a>
                </div>
            </div>
        </template>
    </the-drop-down>
</template>

<script>
    import TheDropDown from "@/components/Tools/TheDropDown";
    export default {
        name: "Notifications",
        components: {TheDropDown}
    }
</script>

<style lang="scss">
#notifications{
    .dropdown{
        width: 220px;
        .notification{
            .header{
                small{
                    opacity: 0.5;
                }
                h4{
                    padding-right: 15px;
                    position: relative;
                    &:before{
                        content: '•';
                        font-size: 30px;
                        color: var(--yellow);
                        position: absolute;
                        right: 0;
                        top: 60%;
                        transform: translateY(-50%);
                    }
                }
            }
            p{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>