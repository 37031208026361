import Vue from 'vue';

Vue.use(require('vue-cookies'))

const that = Vue.prototype

const state = Vue.observable({
    token: localStorage.token || Vue.$cookies.get('--abcd14589') || false,
    loading: false,
    miniLoading: false,
    gotten: false,
    loop: false,

    userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        remember: false,
        towStepAuthenticationType: '',
        authenticationType: '',
        authorized: false,
        pending: false,
        supervisor: false,
        userAccountLevel: '',
        totalTradesVolume: 0,
        fee: 0.4,
    },

    shopInfo: {
        minSell: 0,
        maxBuy: 0,
        lastPrice: 0,
        change24: 0
    },

    theme: localStorage.theme || 'dark',
    menu: true,
    error: {},

    sellAmount: '',
    buyAmount: '',
    sellPrice: '',
    buyPrice: '',
})

that.state = state
// export default state