<template>
    <transition name="side-left">
        <div :mini="$route.name === 'Market'" v-if="show" class="back-drop" id="sidebar" @click.self="$emit('toggle',false)">
            <div class="main-menu d-flex-c-ast-jfs-gap15 main-menu">
                <side-bar-item v-for="item in menuItems" :key="item.name" :item="item"/>
            </div>
        </div>
    </transition>
</template>

<script>
    import SideBarItem from "@/components/Panel/sidebar/SideBarItem";

    export default {
        name: "SideBar",
        components: {SideBarItem},
        props: ['show'],
        model: {
            prop: 'show',
            event: 'toggle'
        },
        data() {
            return {
                menuItems: [
                    {
                        label: 'داشبورد',
                        icon: 'dashboard.svg',
                        name: 'Dashboard',
                        route: {name: 'Dashboard'},
                    },
                    {
                        label: 'کیف پول',
                        icon: 'wallets.svg',
                        name: 'Wallet',
                        route: {name: 'Wallet'}
                    },
                    {
                        label: 'بازار ها',
                        icon: 'markets.svg',
                        name: 'Market',
                        route: {name: 'Market'},
                        subMenu: [
                            {
                                label: 'بازار اصلی',
                                name: 'MainMarket',
                                icon: 'main-market.svg',
                                subRoute: 'params.tradeTo',
                                route: {name: 'Market', params: {tradeTo: 'TOMAN', tradeFrom: 'BITCOIN'}}
                            },
                            {
                                label: 'بازار حرفه ای',
                                name: 'ProMarket',
                                icon: 'advance-market.svg',
                                subRoute: 'params.tradeTo',
                                route: {name: 'Market', params: {tradeTo: 'TETHER', tradeFrom: 'BITCOIN'}}
                            },
                        ]
                    },
                    {
                        label: 'تاریخچه',
                        icon: 'history.svg',
                        name: 'History',
                        route: {name: 'History'}
                    },
                    {
                        label: 'دعوت از دوستان',
                        icon: 'invite.svg',
                        name: 'Referral',
                        route: {name: 'Referral'}
                    },
                    {
                        label: 'تنظیمات',
                        icon: 'setting.svg',
                        name: 'Setting',
                        route: {name: 'Setting'}
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
    #sidebar{
        width: 60%;
        max-width: 230px;
        flex-basis: 230px;
        position: fixed;
        z-index: 100;
        flex-shrink: 0;
        flex-grow: 0;
        &:not([fixed]){
            inset: 0;
            background-color: var();
            width: unset;
            max-width: unset;
            top: var(--navbar-h);
        }
        &[fixed]{
            position: sticky;
        }
        top: var(--navbar-h);
        &[mini] {
            .main-menu{
                max-width: 70px;
                .sidebar-item {
                    position: relative;
                    .label {
                        position: absolute;
                        right: 40px;
                        top: 6px;
                        min-width: max-content;
                    }
                }
            }
        }
        .main-menu {
            max-width: 230px;
            padding: 15px;
            background-color: var(--side-back);
            height: calc(100vh - var(--navbar-h));
            color: var(--fontcolor);
            overflow: auto;
            transition: all 0.3s;
        }
    }
</style>