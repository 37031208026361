import Vue from 'vue'

import TrendChart from "vue-trend-chart";
import PerfectScrollbar from '@nima2142/vue2-perfect-scrollbar'
import '@nima2142/vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VueSweetalert2 from 'vue-sweetalert2'
import '@/style/sweetalert2.min.css';
import VueCookies from 'vue-cookies';
import InlineSvg from 'vue-inline-svg';
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import VueFroala from 'vue-froala-wysiwyg'
import 'froala-editor/css/froala_editor.pkgd.min.css';

Vue.use(VueSweetalert2);
Vue.use(PerfectScrollbar);
Vue.use(VueCookies);
Vue.component('inline-svg', InlineSvg);
Vue.component('date-picker', VuePersianDatetimePicker);
Vue.use(VueFroala);
Vue.use(TrendChart);

Vue.$cookies.config('session');
