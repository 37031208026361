import Vue from 'vue';

const that = Vue.prototype

that.$callBackURL = window.location.origin + '/redirect-from-bank'
that.$google_sso = that.$axios.defaults.baseURL + '/users/google-sso'
that.$refURL = window.location.origin + '/sign-up?ref='


that.$coinLabel = {
    TOMAN: 'تومان',
    BITCOIN: 'بیتکوین',
    BITCOIN_CASH: 'بیتکوین کش',
    ETHEREUM: 'اتریوم',
    CLASSIC_ETHEREUM: 'اتریوم کلاسیک',
    LITE_COIN: 'لایت کوین',
    BINANCE_COIN: 'بایننس کوین',
    EOS: 'ایاس',
    STELLAR: 'استلار',
    RIPPLE: 'ریپل',
    TRON: 'ترون',
    DASH: 'دش',
    DOGE_COIN: 'دوج',
    TETHER: 'تتر',
    BTC: 'بیتکوین',
    BCH: 'بیتکوین کش',
    ETH: 'اتریوم',
    ETC: 'اتریوم کلاسیک',
    LTC: 'لایت کوین',
    BNB: 'بایننس کوین',
    // EOS: 'ایاس',
    XLM: 'استلار',
    XRP: 'ریپل',
    TRX: 'ترون',
    // DASH: 'دش',
    DOGE: 'دوج',
    USDT: 'تتر',
    bitcoin: 'بیتکوین',
    'bitcoin-cash': 'بیتکوین کش',
    ethereum: 'اتریوم',
    etc: 'اتریوم کلاسیک',
    'ethereum-classic': 'اتریوم کلاسیک',
    litecoin: 'لایت کوین',
    binancecoin: 'بایننس کوین',
    eos: 'ایاس',
    stellar: 'استلار',
    ripple: 'ریپل',
    tron: 'ترون',
    dash: 'دش',
    dogecoin: 'دوج',
    tether: 'تتر',
}

that.$coinUnit = {
    TOMAN: 'تومان',
    BITCOIN: 'BTC',
    BITCOIN_CASH: 'BCH',
    ETHEREUM: 'ETH',
    CLASSIC_ETHEREUM: 'ETC',
    LITE_COIN: 'LTC',
    BINANCE_COIN: 'BNB',
    EOS: 'EOS',
    STELLAR: 'XLM',
    RIPPLE: 'XRP',
    TRON: 'TRX',
    DASH: 'DASH',
    DOGE_COIN: 'DOGE',
    TETHER: 'USDT',
}

that.$coin2Snake = {
    BTC: 'BITCOIN',
    BTH: 'BITCOIN_CASH',
    ETH: 'ETHEREUM',
    ETC: 'CLASSIC_ETHEREUM',
    BCH: "BITCOIN_CASH",
    LTC: 'LITE_COIN',
    BNB: 'BINANCE_COIN',
    EOS: 'EOS',
    XLM: 'stellar',
    XRP: 'RIPPLE',
    TRX: 'TRON',
    DASH: 'DASH',
    DOGE: 'DOGE_COIN',
    USDT: 'TETHER',
    bitcoin: 'BITCOIN',
    'bitcoin-cash': 'BITCOIN_CASH',
    ethereum: 'ETHEREUM',
    etc: 'CLASSIC_ETHEREUM',
    'ethereum-classic': 'CLASSIC_ETHEREUM',
    litecoin: 'LITE_COIN',
    binancecoin: 'BINANCE_COIN',
    eos: 'EOS',
    stellar: 'stellar',
    ripple: 'RIPPLE',
    tron: 'TRON',
    dash: 'DASH',
    dogecoin: 'DOGE_COIN',
    tether: 'TETHER',
}

that.$sortCoins = ['bitcoin', 'ethereum', 'ethereum-classic', 'bitcoin-cash', 'litecoin', 'ripple', 'tether', 'dash', 'dogecoin']

that.$errorHandeling = {
    BAD_REQUEST: 'اطلاعات ارسال شده اشتباه است',
    NOT_FOUND: 'اطلاعات درخواستی یافت نشد',
    FINNOTECH_NOT_COMPLETED: 'کاربر فرآیند فینوتک را تکمیل نکرده است',
    CONFLICT: 'اجازه ندارید',
    FORBIDDEN: 'دسترسی لازم را ندارید',
    MOBILE_NUMBER_NOT_FOUND: 'شماره همراه ثبت نشده است',
    'Invalid Phone Number': 'شماره همراه اشتباه است.',
    'Old Password Is Incorrect': 'رمز قبلی اشتباه است.',
    'user not found': 'نام کاربری یافت نشد',
    'Not Enough Balance': 'موجودی شما کافی نمیباشد.',
    'NOT_ENOUGH_BALANCE_TOMAN': 'موجودی تومانی شما کافی نمیباشد.',
    'SMS_OTP_UNAVAILABLE': 'قابلیت پیامک فعال نمیباشد.',
    'MARKET_IS_NOT_ACTIVE': 'این بازار فعال نمیباشد.',
    'incorrect password!!!': 'نام کاربری یا رمز عبور اشتباه است',
    'Incorrect username or password.': 'نام کاربری یا رمز عبور اشتباه است',
    'no user found with entered username': 'نام کاربری یا رمز عبور اشتباه است',
    'Not Enough HDWallets': 'خطایی رخ داده است با پشتیبانی تماس بگیرید',
    'Not Enough Wallets': 'خطایی رخ داده است با پشتیبانی تماس بگیرید',
    'Incorrect OTP': 'کد وارد شده اشتباه است.',
    'Incorrect OTP Code.': 'کد وارد شده اشتباه است.',
    'Otp code is not valid.': 'کد وارد شده اشتباه است.',
    'Code Expired': 'کد شما منقضی شده است.',
    'username exist.': 'اکانت تکراریست',
    'You Dont Have This Coin Wallet': 'کیف پول این ارز را ندارید',
    'You Don\'t Have Enough Credit': 'موجودی کافی نیست'
}