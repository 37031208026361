//auth
const AuthBackBox = () => import("@/components/Auth/AuthBackBox");
const Login = () => import("@/components/Auth/Login");
const Register = () => import("@/components/Auth/Register");
const OTP = () => import("@/components/Auth/OTP");
const ForgetPass = () => import("@/components/Auth/ForgetPass");
const ResetPass = () => import("@/components/Auth/ResetPass");
//panel
const Panel = () => import("@/components/Panel/Panel");
const Profile = () => import("@/components/Panel/Profile/Profile");
const Wallet = () => import("@/components/Panel/Wallet/Wallet");
const History = () => import("@/components/Panel/History/History");
const Setting = () => import("@/components/Panel/Setting/Setting");
const Referral = () => import("@/components/Panel/Referral/Referral");
const Dashboard = () => import("@/components/Panel/Dashboard/Dashboard");
const Market = () => import("@/components/Panel/Market/Market");
const Messages = () => import("@/components/Panel/Messages/Messages");
//home
const Home = () => import("@/components/Home/Home");
const FAQ = () => import("@/components/Help/FAQ");
const HowToUse = () => import("@/components/Help/HowToUse");
const Policy = () => import("@/components/Help/Policy");
const Markets = () => import("@/components/Help/Markets");

export default [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    //     {
    //         path:'/fromgoogle',
    //         redirect:'/auth/from-google'
    //     },
    //     {
    //         path: '/redirect-from-bank',
    //         redirect:'/panel/toman-deposit'
    //     },
    {
        path: "/auth",
        component: AuthBackBox,
        // redirect: '/auth/login',
        children: [
            {
                path: "login",
                name: "Login",
                component: Login,
            },
            {
                path: "register",
                name: "Register",
                component: Register,
            },
            {
                path: "TWO_STEP_*",
                name: "OTP",
                component: OTP,
                props: true
            },
            {
                path: "forget-pass",
                name: "ForgetPass",
                component: ForgetPass,
            },
            //     {
            //         path: "from-google",
            //         name: "FromGoogle",
            //         component: FromGoogle,
            //     },
            {
                path: "RESET_PASS_*",
                name: "ResetPass",
                component: ResetPass,
                props: true
            },
            {
                path: "*",
                redirect: 'login',
            },
        ],
    },
    {
        path: "/panel",
        name: "Panel",
        component: Panel,
        children: [
            {
                path: "profile",
                name: "Profile",
                component: Profile,
            },
            {
                path: "history",
                name: "History",
                component: History,
            },
            {
                path: "settings",
                name: "Setting",
                component: Setting,
            },
                        {
                            path: "messages",
                            name: "Messages",
                            component: Messages,
                        },
            //             {
            //                 path: "support",
            //                 name: "Support",
            //                 component: Support,
            //             },
            //             {
            //                 path: "support/massage/:id",
            //                 name: "SupportMassage",
            //                 component: SupportMassage,
            //                 props: true
            //             },
            {
                path: "referral",
                name: "Referral",
                component: Referral,
            },
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: Dashboard,
            },
            {
                path: 'wallet',
                name: 'Wallet',
                component: Wallet,
            },
            {
                path: 'market/:tradeTo/:tradeFrom',
                name: 'Market',
                component: Market,
                props: true
            },
            //             {
            //                 path: 'fino-verifying',
            //                 name: 'finoVerifying',
            //                 component: finoVerifying,
            //             },
            //             {
            //                 path: 'toman-deposit',
            //                 name: 'TomanDeposit',
            //                 component: RedirectFromBank,
            //             },
        ],
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: FAQ,
    },
    {
        path: '/how-to-use',
        name: 'HowToUse',
        component: HowToUse,
    },
    {
        path: '/policy',
        name: 'Policy',
        component: Policy,
    },
    {
        path: '/markets',
        name: 'Markets',
        component: Markets
    },
    //     {
    //         path: "/helper",
    //         name: 'Helper',
    //         component: Helper,
    //         children: [
    //             {
    //                 path: "about-us",
    //                 name: "AboutUs",
    //                 component: AboutUs,
    //             },
    //             {
    //                 path: "faq",
    //                 name: "FAQ",
    //                 component: FAQ,
    //             },
    //             {
    //                 path: "privacy",
    //                 name: "Privacy",
    //                 component: Privacy,
    //             },
    //             {
    //                 path: "how-to",
    //                 name: "HowTo",
    //                 component: HowTo,
    //             },
    //             {
    //                 path: "markets",
    //                 name: "Markets",
    //                 component: Markets,
    //             },
    //             {
    //                 path: "fees",
    //                 name: "Fees",
    //                 component: Fees,
    //             },
    //         ],
    //     },
    //     //ali-sa
    //     {
    //         path: '/trade/adv/:tradeTo/:tradeFrom',
    //         name: 'advanced-Trade',
    //         component: Trade,
    //         props: true
    //     },
    //     {
    //         path: '/trade/simple/:tradeTo/:tradeFrom',
    //         name: 'simple-Trade',
    //         component: simpleTrade,
    //         props: true
    //     },
    //     {
    //         path: '/dashboard',
    //         name: 'Dashboard',
    //         component: Dashboard,
    //     },
    //     {
    //         path: "/trade/otc",
    //         name: "OTC",
    //         component: OTC,
    //     },
]