<template>
    <div class="account-active main d-flex-r-ac-gap20">
<!--        <inline-svg class="gradient-hover" title="پشتیبانی" :src="require('@/assets/Icons/menu/headPhone.svg')" @click="$push({name : 'Sopport'})"/>-->
        <notifications :key="$route.name"/>
        <div class="main d-flex-ac-gap10 gradient-template-gold-linear back s-radius">
            <inline-svg title="حساب کاربری" :src="require('@/assets/Icons/user.svg')" @click="$emit('Profile')"/>
            <a class="l-font" :href="$href({name : 'Profile'})" @click.prevent="$push({name : 'Profile'})"> آیلین ایمن طلب </a>
            <inline-svg title="خروج" :src="require('@/assets/Icons/logout.svg')" @click="$logout"/>
        </div>
<!--        <the-drop-down :items="dropDownItems" label="مرجان خلیل پور" @clicked="$event.name === 'logout' ? $logout() : ''" :key="$route.name"/>-->
    </div>
</template>

<script>
    // import TheDropDown from "@/components/Tools/TheDropDown";

    import Notifications from "@/components/Navbar/Notifications";
    export default {
        name: "AccountActive",
        components: {Notifications},
        data() {
            return {
                dropDownItems: [
                    {
                        label: 'حساب کاربری',
                        name: 'Profile',
                        route: {name: 'Profile'},
                        icon: require('@/assets/Icons/user.svg')
                    },
                    {
                        label: 'تنظیمات',
                        name: 'Setting',
                        route: {name: 'Setting'},
                        icon: require('@/assets/Icons/menu/setting.svg')
                    },
                    {
                        label: 'خروج',
                        name: 'Logout',
                        route: {name: 'Login'},
                        icon: require('@/assets/Icons/logout.svg')
                    },
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .main {
        padding: 3px 10px;
    }
</style>
<style>
    svg.active .fillable{
        fill: var(--red) !important;
    }
</style>