import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

const that = Vue.prototype

Vue.use(VueRouter);

const router = new VueRouter({
    linkActiveClass: "RouterActive",
    mode: "history",
    routes: routes,

    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        return new Promise(() => {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
            }, 500)
        })
    }
});


const forLogin = [
    'History',
    'Dashboard',
    'Wallet',
    'Setting',
    'Messages',
    'Profile',
    'Orders',
    'Trade',
]
const unAuthorUser = [
    // 'History',
    // 'Dashboard',
    // 'Wallet',
    // 'Orders',
]
const otp = [
    'TwoStep',
    'ResetPass',
]
const auth = [
    'Login',
    'Register',
    'ForgetPass',
]

router.beforeEach(async (to, from, next) => {

    if (screen.width <= 768) {
        that.state.menu = false
    }

    that.state.loading = true
    if (that.state.token) {

        if (!that.state.gotten) {
            // await that.$getUserInfo()
            that.state.loading = true
            // await that.$getFees()
        }

        that.state.loading = true
        if (otp.includes(to.name) || auth.includes(to.name))
            next('/')
        else if (to.name === 'Trade') {
            try {
                that.state.loading = true
                // await that.$axios(`/markets/${to.params.tradeFrom}_${to.params.tradeTo}`)
                next()
            } catch (e) {
                if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                    await that.$error('بازار غیرفعال است')
                    if (otp.includes(from.name)) {
                        next('/')
                    } else {
                        next(from)
                    }
                }
            }
        } else {
            if (that.state.userInfo.authorized) {
                next()
            } else {
                if (otp.includes(from.name) && !that.state.userInfo.pending)
                    next({name: 'Profile'})
                else if (unAuthorUser.includes(to.name)) {
                    that.state.loading = false
                    const res = await that.$error('عدم دسترسی', 'برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود', 'error', 'حساب کاربری')
                    if (res.isConfirmed) {
                        next('/useraccount')
                    } else {
                        next(from)
                    }
                } else next()
            }
        }
    } else {
        if ((otp.includes(to.name) && !Vue.$cookies.isKey('username')) || forLogin.includes(to.name))
            next({name: 'Login'})
        else {
            next()
        }
    }
    that.state.loading = false
    next()
})
router.afterEach((to, from) => {
    that.state.error = {}
    console.log(to, from)
})

export default router
