import Vue from "vue";
import App from "./App.vue";

import "@/style/style.scss";
// import "@/style/font-icon/css/fontello.css";

import "@/lib/globalLibraries"

Vue.config.productionTip = false

import router from '@/lib/router'
import '@/lib/state'
import '@/lib/axios'
import '@/lib/globalConstants'
import '@/lib/globalFunctions'

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
