<template>
    <div class="main-dropdown d-flex-r-g0-s0" :align="align || 'left'" tabindex="-1" ref="label">
        <span class="d-flex-r-ac-jsb-g0-s0-gap15 dropdown-label">
            <slot name="label">
                <inline-svg :src="require('@/assets/Icons/user.svg')"/>
                <a v-if="label" class="text-gradient-hover">{{label}}</a>
            </slot>
            <slot name="icon">
                <inline-svg :src="require('@/assets/Icons/arrow-bottom.svg')"/>
            </slot>
        </span>
        <div class="dropdown">
            <slot name="dropdown">
                <div class="dropdown-box card">
                    <a v-for="item in items" :key="item.name" class="item flex-row-0 gap-5"
                       @click.prevent="click(item)">
                        <slot name="item" :item="item">
                            <inline-svg width="22" v-if="item.icon" :src="item.icon"/>
                            <span class="text-gradient-hover">{{item.label}}</span>
                        </slot>
                    </a>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TheDropDown",
        props: ['items', 'label', 'align'],
        emits: ['clicked'],
        methods: {
            click(e) {
                this.$push(e.route)
                this.$emit('clicked', e)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .main-dropdown {
        position: relative;
        color: var(--fontcolor);
        height: 100%;

        &[align = 'left'] * {
            left: 0;
        }

        &[align = 'right'] * {
            right: 0;
        }

        .dropdown {
            position: absolute;
            /*left: 0;*/
            top: 100%;
            padding-top: 5px;
            display: none;
            z-index: 102;

            &:focus-within {
                display: unset;
            }

            .dropdown-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;
                width: 250px;

                .item {
                    padding: 10px;
                    cursor: pointer;
                    transition: background-color 0.3s;

                    &:nth-child(even) {
                        background-color: var(--white-hover)
                    }

                    &:hover {
                        background-color: var(--hover);

                        .text-gradient-hover {
                            background-image: -webkit-linear-gradient(90deg, var(--yellow1), var(--yellow2));
                            background-image: linear-gradient(90deg, var(--yellow1), var(--yellow2));
                            color: transparent;
                            -webkit-text-fill-color: transparent;
                        }

                        svg {
                            fill: url(#yellow-gradient);
                        }
                    }
                }
            }
        }

        .dropdown-label {
            cursor: pointer;

            .text-gradient-hover {
                padding-left: 5px;
            }
        }

        &:is(:hover,:focus) {
            .dropdown-label {
                .text-gradient-hover {
                    background-image: -webkit-linear-gradient(90deg, var(--yellow1), var(--yellow2));
                    background-image: linear-gradient(90deg, var(--yellow1), var(--yellow2));
                    color: transparent;
                    -webkit-text-fill-color: transparent;
                }

                svg {
                    fill: url(#yellow-gradient);
                }
            }

            .dropdown {
                display: unset;
            }
        }
    }
</style>