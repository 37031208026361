<template>
    <div class="nav-bar d-flex-ast-jc"
         :class="{
        'no-border' : ['Login','Register','OTP','ForgetPass','ResetPass','Home'].includes($route.name),
        'no-back' : ['Home'].includes($route.name),
        '-jfs no-border' : $correntRouteIS('Panel',0)}">
        <div class="container main d-flex-r-ac-jsb-g0-s0-gap30">
            <inline-svg class="burger-menu" width="40" height="25" :src="require('@/assets/Icons/menu/dashboard.svg')"
                        v-if="$route.matched[0].name === 'Panel'" @click="$emit('dash')" key="1"/>
            <div class="logo d-flex-ac-jfs-gap5" @click="$push({name : 'Home'})">
                <img height="40px" width="40px" src="@/assets/logo.png" alt="">
                <aside>
                    <h2>صرافی زرکوین</h2>
                    <small class="s-font">بازار آنلاین خرید و فروش رمزارزها</small>
                </aside>
            </div>
            <transition name="fade-in" mode="out-in" duration="50">
                <inline-svg class="burger-menu" width="40" height="25" :src="require('@/assets/Icons/arrow-left.svg')"
                            v-if="show" @click="$emit('toggle',show=false)" key="1"/>
                <inline-svg class="burger-menu" height="40" :src="require('@/assets/Icons/menu-outline.svg')"
                            v-else @click="$emit('toggle',show=true)" key="2"/>
            </transition>
            <account-active v-if="state.token"/>
            <account-deactive v-else/>
            <div class="menu d-flex-r-ac-g0-s0-gap30 grow-1 shrink-1">
                <a class="gradient-template-gold-linear text-gradient-hover" :href="$href(item.route)"
                   @click.prevent="$push(item.route)"
                   :class="{activeTab : $route.name === item.route.name}" v-for="item in items" :key="item.name">{{item.label}}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import AccountActive from "@/components/Navbar/Account/AccountActive";
    import AccountDeactive from "@/components/Navbar/Account/AccountDeactive";

    export default {
        name: "Navbar",
        components: {AccountDeactive, AccountActive},
        props: ['items', 'show'],
        emits: ['toggle'],
        model: {
            prop: 'show',
            event: 'toggle'
        },
    }
</script>

<style lang="scss" scoped>
    .nav-bar {
        background-color: var(--nav-back);
        backdrop-filter: blur(2px);
        box-shadow: 0 0 3px #ffffff19;
        height: 60px;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        color: var(--fontcolor);
        z-index: 100;

        &.no-back {
            background-color: transparent;
        }

        .main {

            .menu {
                overflow-x: auto;
                @media (max-width: 768px) {
                    display: none;
                }
            }

            .burger-menu {
                display: none;
                @media (max-width: 768px) {
                    display: unset;
                    ~ {
                        .menu {
                            display: none;
                        }
                    }
                }
            }

            .account-active, .deactive {
                @media (max-width: 768px) {
                    display: none;
                }
            }

            .logo {
                cursor: pointer;

                aside {
                    text-align: right;
                }
            }
        }
    }
</style>