<template>
    <div class="deactive d-flex-r-ac-g1-s1-gap5 gradient-template-gold-linear back s-radius">
        <a class="gradient-template-gray-linear text-gradient-hover" :href="$href({name : 'Login'})" @click.prevent="$push({name :'Login'})">ورود</a>
        <pre> / </pre>
        <a class="gradient-template-gray-linear text-gradient-hover" :href="$href({name : 'Register'})" @click.prevent="$push({name :'Register'})">ثبت نام</a>
    </div>
</template>

<script>
    export default {
        name: "AccountDeactive"
    }
</script>

<style lang="scss" scoped>
    .deactive {
        color: var(--fontcolor);
        font-size: 16px;
        padding: 3px 15px;
    }
</style>